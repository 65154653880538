import {
  getCldUser,
  getIsPurchaseOrderRequired,
  getUser,
  saveUser,
  updateUser,
  updateUserEmails,
} from "../api/user";
import { defineStore } from "pinia";
import { ref } from "vue";
import { CLD_ENV, NODE_ENV } from "~~/constants/env";
import { useURLs } from "~~/constants/api.urls";
import nuxtStorage from "nuxt-storage";
import { validateNSendContact } from "~/api/turnStile";
import { useI18n } from "vue-i18n";
import { useOrderStore } from "./OrderStore";
import { useCldURLs } from "~/constants/apiv2.urls";

// import { EventBus } from "~/utils/eventBus.js";
export const useUserStore = defineStore("user", () => {
  const orderStore = useOrderStore();
  const isAuthenticated = ref(false);
  // user info On Nest Server
  const { t } = useI18n();
  const user = ref({});
  const cldUser = ref({});

  const pending = ref(false);
  const preSignupData = ref();
  const contactEmail = ref();
  const preSignupVatData = ref({});
  const signupError = ref({ value: false, message: "" });
  const loginRes = ref({ value: false, message: "" });
  const preSignupResponse = ref();
  const existingEmail = ref(false);
  const postSignupResponse = ref();
  const categoriesPreference = ref({});
  const isPurchaseOrderRequired = ref(false);
  // Usefetch for login

  const loginViaH3 = async ({ userName, password }) => {

    loginRes.value = { value: false, message: "" };

    const { error, data } = await useFetch("/api/auth/login", {
      method: "POST",
      body: JSON.stringify({ userName, password }),
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (error.value) {
      loginRes.value.message = error.value.data.label;
    }
    else {
      loginRes.value.message = data.value.label;
      if (data && data.value && data.value?.success) {

        // nuxtStorage.localStorage.setData(
        //   "token",
        //   "access_token bearer 09022122xxx",
        //   12,
        //   "h"
        // );
        // TODO add init mercatore-engine on CLD side
        // nuxtStorage.localStorage.setData(
        //   "refresh_token",
        //   data.value.refresh_token,
        //   24,
        //   "h"
        // );
        isAuthenticated.value = true;
        loginRes.value.value = data.value.success;

        getCldUserData();
      }
    }
  };
  const logout = async () => {

    nuxtStorage.localStorage.removeItem("accountingSituation");
    orderStore.setAccountingSituationLocalStorage();
    user.value = {};
    cldUser.value = {};
    isAuthenticated.value = false;
    
    await useFetch("/api/auth/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })

  };
  const preSignup = async (payload) => {
    pending.value = true;
    const url = useCldURLs[CLD_ENV].auth.pre_register;
    // const url = useURLs[CLD_ENV].CLD_PREREGISTOR;
    const { data } = await useFetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });
    pending.value = false;
    preSignupResponse.value = data.value;
    // nuxtStorage.localStorage.setData("pre-signup", data.value.data, 100, "h");
  };
  const sendContactEmailCld = async ({
    email,
    message,
    phone,
    name,
    token,
  }) => {
    
    const turnStileResponse = await validateNSendContact({
      token,
      email,
      message,
      phone,
      name,
    });
    contactEmail.value = turnStileResponse.data;
    // const { data } = await sendContactEmail({ email, message, phone, name });
    // contactEmail.value = data.value?.data;
  };

  const getPreSignup = async (id) => {
    pending.value = true;
    const url = `${useCldURLs[CLD_ENV].auth.pre_register}?id=${id}&serviceId=${id}`;
    const { data } = await useFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    
    pending.value = false;
    if (data.value.label === "ALREADY_VERIFIED") {
      signupError.value = {
        value: true,
        message: "ALREADY_VERIFIED",
      };
    } else if (data.value.label === "INVALID_UNIQUE_ID") {
      signupError.value = {
        value: true,
        message: "INVALID_UNIQUE_ID",
      };
    } else {
      const {
        data: { client, vatData },
      } = data.value;
      console.log("client ", client);
      preSignupData.value = client;
      preSignupVatData.value = vatData;
    }
  };
  const emailExists = async (email) => {
    pending.value = true;
    const url = `${useCldURLs[CLD_ENV].utility.email_exists}?serviceId=emailchecknoid&email=${email}`;

    const { data } = await useFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },

    });
    existingEmail.value = data.value;
    pending.value = false;
    return data;
  };

  const CheckVatExistAndisValid = async (vat, countryCode) => {
    pending.value = true;
    const url = `${useCldURLs[CLD_ENV].utility.check_vat}?countryCode=${countryCode}&vat=${vat}&id=${vat}`;

    // const url = `${useURLs[CLD_ENV].CHECK_VAT_EXIST_AND_ISVALID}?countryCode=${countryCode}&vat=${vat}`;
    const { data } = await useFetch(url, {
      key: vat,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    pending.value = false;
    return data;
  };
  const postSignup = async (payload) => {
    pending.value = true;
    const url = useCldURLs[CLD_ENV].auth.post_register;
    const { data } = await useFetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });

    pending.value = false;
    if (data.value.code === 500) {
      signupError.value = {
        value: true,
        message: data.value.label,
      };
    }

    postSignupResponse.value = data.value;
  };
  const setSignupError = (v) => {
    signupError.value = v || {
      value: true,
      message: "ERROR_SIGNUP",
    };
  };
  const saveUserCategoryPrefrence = async (payload) => {
    const data = await saveUser(payload);
    return data;
  };
  const updateUserCategoryPrefrence = async (payload) => {
    const data = await updateUser(payload);
    return data;
  };
  const saveOrUpdateUser = async (payload) => {
    if (user.value._id) {
      // update

      const resp = await updateUser(payload);
      if (resp && resp.value && resp.value?.data) {
        user.value = resp.value.data;
        useNuxtApp().$showToast({
          msg: t("save_success.desc"),
          type: "success",
        });
      } else {
        useNuxtApp().$showToast({
          msg: t("save_fail.changes_not_saved"),
          type: "error",
        });
      }
    } else {
      const resp = await saveUser(payload);

      if (resp && resp.value && resp.value?.data) {
        useNuxtApp().$showToast({
          msg: t("save_success.desc"),
          type: "success",
        });
      } else {
        useNuxtApp().$showToast({
          msg: t("save_fail.changes_not_saved"),
          type: "error",
        });
      }
    }
    // showAlert({
    //   title: useConstants.SAVE.TITLE[selectedLanguage.value],
    //   text: useConstants.SAVE.DESC[selectedLanguage.value],
    // });
  };
  const updateUserNewsLetterStatus = async ({ _id, newsLetterSeen }) => {

    const url = useURLs[NODE_ENV].UPDATE_USER_NEWSLETTER_STATUS;
    const { data } = await useFetchApi(url, {
      key: _id,
      method: "PATCH",
      body: { _id, newsLetterSeen },
      headers: {
        client_id: useURLs[NODE_ENV].CLIENT_ID,
        client_secret: useURLs[NODE_ENV].CLIENT_SECERET,
        "Content-Type": "application/json",

      },
      retry: 1,
    });
    return data;

  };
  const getUserData = async () => {
    const data = await getUser();
    if (data?.value && data.value?.data) {
      user.value = data.value.data;
      const category = Object.assign(
        {},
        ...data.value.data.categories.map((x) => ({ [x.key]: x }))
      );

      categoriesPreference.value = category;
      // console.log("categoriesPreference.value", categoriesPreference.value);
    }
    return user;
  };
  const getCldUserData = async () => {
    const data = await getCldUser();
    if (data.value) {
      cldUser.value = data.value.data;
    }
  };

  const getIsPORequired = async () => {
    const data = await getIsPurchaseOrderRequired();
    if (data && data.value) {
      isPurchaseOrderRequired.value = data.value.data;
    }
  };
  const updateCldUserEmails = async (payload) => {
    const data = await updateUserEmails(payload);
    console.log("updateUserEmails Data", data.value);
    return data;
  };
  const setIsAuthenticated = () => {
    isAuthenticated.value = !!useCookie('isAuthenticated').value;
  };

  const sendCredentialsEmail = async ({ id, uniqueID }) => {
    try {
      //TODO add turnStileToken
      const turnStileToken = true;
      if (turnStileToken && id && uniqueID) {
        const url = `${useURLs[CLD_ENV].SEND_CREDENTIALS_EMAIL}?id=${id}&uniqueID=${uniqueID}`;
        const { data } = await useFetch(url, {
          key: uniqueID,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (data.value?.data?.code == 200) {
          useNuxtApp().$showToast({
            msg: t("EMAIL_SENT"),
            type: "info",
          });
        } else {
          useNuxtApp().$showToast({
            msg: t("email_credentials_error"),
            type: "info",
          });
        }
        return data;
      }
    } catch (error) {
      console.log("error", error);
    }
    return false;
  };
  const forgotPassword = async ({ email, selectedLanguage }) => {

    const url = useCldURLs[CLD_ENV].auth.forgot_password;
    const { data } = await useFetch(url, {
      key: email,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: { email, selectedLanguage },
    });

    if (data.value?.code == 200) {
      useNuxtApp().$showToast({
        msg: t(data.value?.message),
        type: "info",
      });
    } else {
      useNuxtApp().$showToast({
        msg: t(data.value?.message),
        type: "error",
      });
    }
    return data.value?.code == 200;
  };
  const resetPassword = async ({ guid, selectedLanguage }) => {
    const url = `${useCldURLs[CLD_ENV].auth.reset_password}?guid=${guid}&lang=${selectedLanguage}`;
    const { data } = await useFetch(url, {
      key: guid,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (data.value?.code == 200) {
      useNuxtApp().$showToast({
        msg: t(data.value?.message),
        type: "info",
      });
    } else {
      useNuxtApp().$showToast({
        msg: t(data.value?.message),
        type: "error",
      });
    }
    return data.value?.code == 200;
  }

  onMounted(() => {
    isAuthenticated.value = !!useCookie('isAuthenticated').value
  });
  useAsyncData('hyderate-token', () => {
    isAuthenticated.value = !!useCookie('isAuthenticated').value
    return true;
  })
  return {
    pending,
    user,
    cldUser,
    categoriesPreference,
    existingEmail,
    emailExists,
    CheckVatExistAndisValid,
    saveUserCategoryPrefrence,
    saveOrUpdateUser,
    updateUserCategoryPrefrence,
    updateUserNewsLetterStatus,
    getUserData,
    getCldUserData,
    updateCldUserEmails,
    sendCredentialsEmail,
    logout,
    isAuthenticated,
    loginRes,
    getPreSignup,
    preSignup,
    postSignup,
    preSignupData,
    preSignupVatData,
    signupError,
    setSignupError,
    preSignupResponse,
    postSignupResponse,
    sendContactEmailCld,
    contactEmail,
    setIsAuthenticated,
    getIsPORequired,
    isPurchaseOrderRequired,
    forgotPassword,
    resetPassword,
    loginViaH3
  };
});
